import * as i0 from '@angular/core';
import { Injectable, Directive, Input, HostListener, EventEmitter, Component, ChangeDetectionStrategy, ViewChildren, Output, NgModule } from '@angular/core';
import * as i2 from '@angular/forms';
import { UntypedFormArray, UntypedFormControl, Validators, ReactiveFormsModule, FormsModule } from '@angular/forms';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["otpInputElement"];
function NgxOtpInputComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 3)(1, "label")(2, "input", 4, 0);
    i0.ɵɵlistener("keyup", function NgxOtpInputComponent_div_1_Template_input_keyup_2_listener($event) {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleKeyUp(i_r2, $event.key));
    })("keyup.backspace", function NgxOtpInputComponent_div_1_Template_input_keyup_backspace_2_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleDelete(i_r2));
    })("keyup.arrowLeft", function NgxOtpInputComponent_div_1_Template_input_keyup_arrowLeft_2_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.stepBackward(i_r2));
    })("keyup.arrowRight", function NgxOtpInputComponent_div_1_Template_input_keyup_arrowRight_2_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.stepForward(i_r2));
    })("focus", function NgxOtpInputComponent_div_1_Template_input_focus_2_listener() {
      const i_r2 = i0.ɵɵrestoreView(_r1).index;
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.handleFocus(i_r2));
    });
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const control_r4 = ctx.$implicit;
    const i_r2 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngClass", ctx_r2.otpConfig.classList == null ? null : ctx_r2.otpConfig.classList.inputBox);
    i0.ɵɵadvance();
    i0.ɵɵattribute("aria-label", ctx_r2.ariaLabels[i_r2]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("id", "ngx-otp-input-" + i_r2)("formControl", control_r4)("ngxOtpPattern", ctx_r2.otpConfig.pattern)("type", ctx_r2.otpConfig.isPasswordInput ? "password" : "text")("ngClass", (ctx_r2.styles == null ? null : ctx_r2.styles.length) > 0 ? ctx_r2.styles[i_r2] : null);
  }
}
var NgxOtpBehavior;
(function (NgxOtpBehavior) {
  NgxOtpBehavior[NgxOtpBehavior["DEFAULT"] = 0] = "DEFAULT";
  NgxOtpBehavior[NgxOtpBehavior["LEGACY"] = 1] = "LEGACY";
})(NgxOtpBehavior || (NgxOtpBehavior = {}));
class NgxOtpInputService {
  init2DArray(size) {
    return new Array(size).fill(new Array());
  }
  toArray(value) {
    return Array.isArray(value) ? value : [value];
  }
  addItemToAll(source, items) {
    if (source?.length > 0) {
      return source.map(entry => entry.concat(items));
    }
  }
  removeItemFromAll(source, items) {
    if (source?.length > 0) {
      return source.map(entry => entry.filter(item => !items.includes(item)));
    }
  }
  addItemAtIndex(source, index, items) {
    if (source?.length > 0) {
      source[index] = source[index].concat(items);
      return source;
    }
  }
  removeItemAtIndex(source, index, items) {
    if (source?.length > 0) {
      source[index] = source[index].filter(item => !items.includes(item));
      return source;
    }
  }
}
NgxOtpInputService.ɵfac = function NgxOtpInputService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxOtpInputService)();
};
NgxOtpInputService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: NgxOtpInputService,
  factory: NgxOtpInputService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxOtpInputService, [{
    type: Injectable
  }], null, null);
})();
class PatternDirective {
  constructor() {
    // allowed keys apart from numeric characters
    this.allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Escape', 'Tab'];
  }
  onKeyDown(e) {
    if (this.allowedKeys.includes(e.key) || e.code === 'KeyA' && e.ctrlKey === true ||
    // Allow: Ctrl+A
    e.code === 'KeyC' && e.ctrlKey === true ||
    // Allow: Ctrl+C
    e.code === 'KeyV' && e.ctrlKey === true ||
    // Allow: Ctrl+V
    e.code === 'KeyX' && e.ctrlKey === true ||
    // Allow: Ctrl+X
    e.code === 'KeyA' && e.metaKey === true ||
    // Cmd+A (Mac)
    e.code === 'KeyC' && e.metaKey === true ||
    // Cmd+C (Mac)
    e.code === 'KeyV' && e.metaKey === true ||
    // Cmd+V (Mac)
    e.code === 'KeyX' && e.metaKey === true // Cmd+X (Mac)
    ) {
      return; // let it happen, don't do anything
    } else if (!this.pattern.test(e.key)) {
      e.preventDefault();
    }
  }
}
PatternDirective.ɵfac = function PatternDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || PatternDirective)();
};
PatternDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: PatternDirective,
  selectors: [["", "ngxOtpPattern", ""]],
  hostBindings: function PatternDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("keydown", function PatternDirective_keydown_HostBindingHandler($event) {
        return ctx.onKeyDown($event);
      });
    }
  },
  inputs: {
    pattern: [0, "ngxOtpPattern", "pattern"]
  },
  standalone: false
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PatternDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxOtpPattern]'
    }]
  }], null, {
    pattern: [{
      type: Input,
      args: ['ngxOtpPattern']
    }],
    onKeyDown: [{
      type: HostListener,
      args: ['keydown', ['$event']]
    }]
  });
})();
class NgxOtpInputComponent {
  constructor(ngxOtpInputService, ref) {
    this.ngxOtpInputService = ngxOtpInputService;
    this.ref = ref;
    this.ngxOtpArray = new UntypedFormArray([]);
    this.ariaLabels = [];
    this.styles = [];
    this.otpConfig = {
      otpLength: 6,
      autofocus: true,
      autoblur: true,
      behavior: NgxOtpBehavior.DEFAULT
    };
    this.defaultPattern = /^\d+$/;
    this.DEFAULT_ARIA_LABEL = 'One time password input';
    this.isNgxOtpArrayDisabled = false;
    this.focusedInputHasValue = false;
    this.otpChange = new EventEmitter();
    this.fill = new EventEmitter();
  }
  onPaste(event) {
    event.preventDefault();
    this.handlePaste(event.clipboardData.getData('text'));
  }
  set disable(isDisabled) {
    this.handleDisable(isDisabled);
    this.isNgxOtpArrayDisabled = isDisabled;
  }
  set config(c) {
    this.otpConfig = {
      ...this.otpConfig,
      ...c
    };
    if (this.otpConfig.classList?.input) {
      this.setInitialStyles();
    }
    if (!c.pattern) {
      this.otpConfig.pattern = this.defaultPattern;
    }
  }
  set status(status) {
    this.handleStatusChange(status);
  }
  ngOnInit() {
    this.setUpOtpForm();
    this.setUpAriaLabels();
    this.LAST_INPUT_INDEX = this.otpConfig.otpLength - 1;
    this.otpFormChangeListener();
  }
  ngAfterViewInit() {
    this.setNativeHTMLElements();
    this.setInitialFocus();
    this.setNumericInputIfPossible();
    this.handleDisable(this.isNgxOtpArrayDisabled);
  }
  ngOnDestroy() {
    this.ngxOtpArray$.unsubscribe();
  }
  clear() {
    this.removeStyleFromAll(this.otpConfig.classList?.inputFilled);
    this.ngxOtpArray.reset();
    this.ref.detectChanges();
    if (this.otpConfig.autofocus) {
      this.setFocus(0);
    }
  }
  handleKeyUp(index, value) {
    if (this.otpConfig.pattern.test(value) && value !== 'Backspace') {
      this.addStyle(index, this.otpConfig.classList?.inputFilled);
      if (!this.ngxOtpArray.valid) {
        this.getFormControlByIndex(index).setValue(value);
        this.stepForward(index);
      } else {
        this.blur();
      }
    }
  }
  handleDelete(index) {
    this.removeStyle(index, this.otpConfig.classList?.inputFilled);
    if (this.otpConfig.behavior === NgxOtpBehavior.LEGACY && !this.focusedInputHasValue || this.otpConfig.behavior !== NgxOtpBehavior.LEGACY) {
      this.stepBackward(index);
    } else {
      this.focusedInputHasValue = false;
    }
  }
  handleFocus(index) {
    this.focusedInputHasValue = !!this.ngxOtpArray.controls[index].value;
    if (this.otpConfig.behavior === NgxOtpBehavior.LEGACY && this.focusedInputHasValue) {
      this.inputs[index].select();
    }
  }
  stepBackward(index) {
    if (index > 0) {
      this.setFocus(index - 1);
    }
  }
  stepForward(index) {
    if (index < this.LAST_INPUT_INDEX) {
      this.setFocus(index + 1);
    }
  }
  otpFormChangeListener() {
    this.ngxOtpArray$ = this.ngxOtpArray.valueChanges.subscribe(values => {
      this.otpChange.emit(values);
      if (this.ngxOtpArray.valid) {
        this.fill.emit(values.join(''));
      }
    });
  }
  setUpOtpForm() {
    for (let i = 0; i < this.otpConfig.otpLength; i++) {
      this.ngxOtpArray.push(new UntypedFormControl(null, [Validators.required]));
    }
  }
  setUpAriaLabels() {
    const labels = this.otpConfig.ariaLabels;
    this.ariaLabels = Array.isArray(labels) ? labels : new Array(this.otpConfig.otpLength).fill(labels || this.DEFAULT_ARIA_LABEL);
  }
  setNativeHTMLElements() {
    this.inputs = this.otpInputElements.map(element => element.nativeElement);
  }
  setInitialFocus() {
    if (this.otpConfig.autofocus) {
      this.setFocus(0);
    }
  }
  setInitialStyles() {
    this.styles = this.ngxOtpInputService.init2DArray(this.otpConfig.otpLength);
    this.addStyleToAll(this.otpConfig.classList.input);
  }
  setFocus(index) {
    this.inputs[index].focus();
  }
  setNumericInputIfPossible() {
    if (this.otpConfig.numericInputMode) {
      this.otpConfig.pattern = this.defaultPattern;
      this.inputs.map(element => {
        element.setAttribute('inputmode', 'numeric');
        element.setAttribute('pattern', '[0-9]*');
      });
    }
  }
  blur() {
    if (this.otpConfig.autoblur) {
      this.inputs.map(input => input.blur());
    }
  }
  handlePaste(value) {
    if (this.otpConfig.pattern.test(value)) {
      let lastIndex = 0;
      value.split('').slice(0, this.otpConfig.otpLength).map((character, index) => {
        this.addStyle(index, this.otpConfig.classList?.inputFilled);
        this.getFormControlByIndex(index).setValue(character);
        lastIndex = index;
      });
      if (this.ngxOtpArray.valid) {
        this.blur();
      } else {
        this.setFocus(lastIndex + 1);
      }
    }
  }
  handleDisable(isDisabled) {
    if (isDisabled) {
      this.ngxOtpArray.disable();
      this.addStyleToAll(this.otpConfig.classList?.inputDisabled);
    } else {
      this.ngxOtpArray.enable();
      this.removeStyleFromAll(this.otpConfig.classList?.inputDisabled);
    }
  }
  handleStatusChange(status) {
    this.removeStyleFromAll([...this.ngxOtpInputService.toArray(this.otpConfig.classList?.inputSuccess), ...this.ngxOtpInputService.toArray(this.otpConfig.classList?.inputError)]);
    if (status) {
      if (status === 'success') {
        this.addStyleToAll(this.otpConfig.classList?.inputSuccess);
      } else if (status === 'error') {
        this.addStyleToAll(this.otpConfig.classList?.inputError);
      }
    }
  }
  getFormControlByIndex(index) {
    return this.ngxOtpArray.controls[index];
  }
  addStyle(index, styles) {
    this.styles = this.ngxOtpInputService.addItemAtIndex(this.styles, index, this.ngxOtpInputService.toArray(styles));
  }
  addStyleToAll(styles) {
    this.styles = this.ngxOtpInputService.addItemToAll(this.styles, this.ngxOtpInputService.toArray(styles));
  }
  removeStyle(index, styles) {
    this.styles = this.ngxOtpInputService.removeItemAtIndex(this.styles, index, this.ngxOtpInputService.toArray(styles));
  }
  removeStyleFromAll(styles) {
    this.styles = this.ngxOtpInputService.removeItemFromAll(this.styles, this.ngxOtpInputService.toArray(styles));
  }
}
NgxOtpInputComponent.ɵfac = function NgxOtpInputComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxOtpInputComponent)(i0.ɵɵdirectiveInject(NgxOtpInputService), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
NgxOtpInputComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NgxOtpInputComponent,
  selectors: [["ngx-otp-input"]],
  viewQuery: function NgxOtpInputComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(_c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.otpInputElements = _t);
    }
  },
  hostBindings: function NgxOtpInputComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("paste", function NgxOtpInputComponent_paste_HostBindingHandler($event) {
        return ctx.onPaste($event);
      });
    }
  },
  inputs: {
    disable: "disable",
    config: "config",
    status: "status"
  },
  outputs: {
    otpChange: "otpChange",
    fill: "fill"
  },
  standalone: false,
  decls: 2,
  vars: 2,
  consts: [["otpInputElement", ""], [1, "ngx-otp-input-container", 3, "ngClass"], ["class", "ngx-otp-input-box", 3, "ngClass", 4, "ngFor", "ngForOf"], [1, "ngx-otp-input-box", 3, "ngClass"], ["maxlength", "1", "autocapitalize", "off", "spellcheck", "false", 1, "ngx-otp-input", 3, "keyup", "keyup.backspace", "keyup.arrowLeft", "keyup.arrowRight", "focus", "id", "formControl", "ngxOtpPattern", "type", "ngClass"]],
  template: function NgxOtpInputComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "form", 1);
      i0.ɵɵtemplate(1, NgxOtpInputComponent_div_1_Template, 4, 7, "div", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngClass", ctx.otpConfig.classList == null ? null : ctx.otpConfig.classList.container);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.ngxOtpArray.controls);
    }
  },
  dependencies: [i2.ɵNgNoValidate, i2.DefaultValueAccessor, i2.NgControlStatus, i2.NgControlStatusGroup, i2.MaxLengthValidator, i2.FormControlDirective, i2.NgForm, i3.NgClass, i3.NgForOf, PatternDirective],
  styles: [".ngx-otp-input-container[_ngcontent-%COMP%]{display:flex}.ngx-otp-input-box[_ngcontent-%COMP%]{margin:0 5px}.ngx-otp-input-box[_ngcontent-%COMP%]:first-child{margin-left:0}.ngx-otp-input-box[_ngcontent-%COMP%]:last-child{margin-right:0}.ngx-otp-input[_ngcontent-%COMP%]{width:35px;height:35px;text-align:center;font-size:1.25rem;border:1px solid #212121;border-radius:4px;outline:0}.ngx-otp-input-disabled[_ngcontent-%COMP%]{opacity:.3}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxOtpInputComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-otp-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<form\n  [ngClass]=\"otpConfig.classList?.container\"\n  class=\"ngx-otp-input-container\"\n>\n  <div\n    *ngFor=\"let control of ngxOtpArray.controls; let i = index\"\n    [ngClass]=\"otpConfig.classList?.inputBox\"\n    class=\"ngx-otp-input-box\"\n  >\n    <label [attr.aria-label]=\"ariaLabels[i]\">\n      <input\n        #otpInputElement\n        [id]=\"'ngx-otp-input-' + i\"\n        [formControl]=\"control\"\n        [ngxOtpPattern]=\"otpConfig.pattern\"\n        [type]=\"otpConfig.isPasswordInput ? 'password' : 'text'\"\n          [ngClass]=\"styles?.length > 0 ? styles[i] : null\"\n        (keyup)=\"handleKeyUp(i, $event.key)\"\n        (keyup.backspace)=\"handleDelete(i)\"\n        (keyup.arrowLeft)=\"stepBackward(i)\"\n        (keyup.arrowRight)=\"stepForward(i)\"\n        (focus)=\"handleFocus(i)\"\n        class=\"ngx-otp-input\"\n        maxlength=\"1\"\n        autocapitalize=\"off\"\n        spellcheck=\"false\"\n      />\n    </label>\n  </div>\n</form>\n",
      styles: [".ngx-otp-input-container{display:flex}.ngx-otp-input-box{margin:0 5px}.ngx-otp-input-box:first-child{margin-left:0}.ngx-otp-input-box:last-child{margin-right:0}.ngx-otp-input{width:35px;height:35px;text-align:center;font-size:1.25rem;border:1px solid #212121;border-radius:4px;outline:0}.ngx-otp-input-disabled{opacity:.3}\n"]
    }]
  }], function () {
    return [{
      type: NgxOtpInputService
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    otpInputElements: [{
      type: ViewChildren,
      args: ['otpInputElement']
    }],
    otpChange: [{
      type: Output
    }],
    fill: [{
      type: Output
    }],
    onPaste: [{
      type: HostListener,
      args: ['paste', ['$event']]
    }],
    disable: [{
      type: Input
    }],
    config: [{
      type: Input
    }],
    status: [{
      type: Input
    }]
  });
})();
class NgxOtpInputModule {}
NgxOtpInputModule.ɵfac = function NgxOtpInputModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || NgxOtpInputModule)();
};
NgxOtpInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NgxOtpInputModule
});
NgxOtpInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [NgxOtpInputService],
  imports: [ReactiveFormsModule, FormsModule, CommonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgxOtpInputModule, [{
    type: NgModule,
    args: [{
      declarations: [NgxOtpInputComponent, PatternDirective],
      imports: [ReactiveFormsModule, FormsModule, CommonModule],
      exports: [NgxOtpInputComponent],
      providers: [NgxOtpInputService]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-otp-input
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgxOtpBehavior, NgxOtpInputComponent, NgxOtpInputModule };
