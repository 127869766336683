import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from "./app-routing-module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatDialogModule} from "@angular/material/dialog";
import {MatInputModule} from "@angular/material/input";
import {MatPaginatorModule} from "@angular/material/paginator";
import {NgToastModule} from "ng-angular-popup";
import {HeaderComponent} from "./components/header/header.component";
import {MenuNavigationComponent} from "./components/menu-navigation/menu-navigation.component";
import {MatIconModule} from "@angular/material/icon";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChipsModule} from "@angular/material/chips";
import {MatSelectModule} from "@angular/material/select";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTableModule} from "@angular/material/table";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatTabsModule} from "@angular/material/tabs";
import {MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {MatButtonModule} from "@angular/material/button";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {DatePipe} from "@angular/common";
import {allIcons, NgxBootstrapIconsModule} from "ngx-bootstrap-icons";
import {MatRadioModule} from "@angular/material/radio";
import {MatFormFieldModule} from "@angular/material/form-field";
import {IconsModule} from "./icons/icons.module";
import {DeletePopupComponent} from './delete-popup/delete-popup.component';
import {AdminAuthGuard} from "./auth/admin-auth.guard";
import {UserAuthGuard} from "./auth/user-auth.guard";
import {AutoFocusDirective} from "./Shared/directive/auto-focus.directive";
import {RouterModule} from "@angular/router";
import {UtilModule} from "./util/util.module";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {LayoutComponent} from './layout/layout.component';
import {AuthInterceptorService} from "./services/auth-interceptor.service";
import {NgOtpInputModule} from "ng-otp-input";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        LayoutComponent,
        HeaderComponent,
        MenuNavigationComponent,
        DeletePopupComponent,
        AutoFocusDirective,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        MatPaginatorModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatExpansionModule,
        MatChipsModule,
        UtilModule,
        MatSelectModule,
        MatCheckboxModule,
        MatInputModule,
        MatDialogModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatTableModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatNativeDateModule,
        MatButtonModule,
        MatButtonToggleModule,
        NgToastModule,
        MatIconModule,
        MatTabsModule,
        NgxBootstrapIconsModule.pick(allIcons),
        RouterModule,
        MatTabsModule,
        DatePipe,
        MatRadioModule,
        IconsModule,
        MatTooltipModule,
        NgOtpInputModule,
        BsDatepickerModule.forRoot()], providers: [
        AdminAuthGuard,
        UserAuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        DatePipe,
        { provide: MAT_DATE_LOCALE, useValue: 'en-IN' },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
