import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: false
})
export class AppComponent implements AfterViewInit {

  private selector: string = 'initialState';

  constructor(@Inject(DOCUMENT) private _document: Document, private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this.removeElementById(this.selector);
  }

  private removeElementById(id: string): void {
    const element = this._document.getElementById(id);
    if (element && element.parentNode) {
      this._renderer.removeChild(element.parentNode, element);
    }
  }
}
